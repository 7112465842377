import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom-v5-compat';
import { useEffectOnce } from 'react-use';

import DefaultButton from 'components/atoms/DefaultButton';
import DefaultCard from 'components/atoms/DefaultCard';
import DefaultCheckbox from 'components/atoms/DefaultCheckbox';
import ErrorBoundaryFallback from 'components/atoms/ErrorBoundaryFallback';
import FarmbotLogoHorizontal from 'components/atoms/FarmbotLogoHorizontal';
import HelmetWrapper from 'components/atoms/HelmetWrapper';
import PageHeaderPusher from 'components/atoms/PageHeaderPusher';
import DefaultForm from 'components/composites/DefaultForm';
import DefaultPageHeader from 'components/composites/DefaultPageHeader';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import PasswordFormItems from 'components/forms/PasswordFormItems';
import UserFormItems from 'components/forms/UserFormItems';
import { loadInvitation } from 'store/modules/invitations/actions';
import InvitationActionType from 'store/modules/invitations/constants';
import { fetchLegalPolicyDocuments } from 'store/modules/legalPolicy/actions';
import { LegalPolicyActionType } from 'store/modules/legalPolicy/constants';
import { useLegalPolicyDocumentsAsArray } from 'store/modules/legalPolicy/hooks';
import { useIsLoading } from 'store/modules/loading/hooks';
import { addUser } from 'store/modules/users/actions';
import UserActionType from 'store/modules/users/constants';
import { LegalPolicyDocumentLabels, LegalPolicyDocumentType } from 'types/legal-policy-document-type.enum';
import Invitation from 'types/models/invitation';
import { LegalPolicyDocument } from 'types/models/legal-policy-document';
import getWhiteLabelConfig from 'utils/get-white-label-config';

import './styles.less';

// ==============================
// TYPES & CONSTANTS
// ==============================
const invitationActions = [InvitationActionType.LOAD_INVITATION_REQUEST];
const addUserActions = [UserActionType.ADD_USER_REQUEST];
const legalPolicyActions = [LegalPolicyActionType.FETCH_LEGAL_POLICY_DOCUMENTS_REQUEST];

// ==============================
// PURE FUNCTIONS
// ==============================
const getDocumentLabel = (policyType: LegalPolicyDocumentType) => {

  if (policyType === LegalPolicyDocumentType.TERMS_OF_SERVICE) {
    return LegalPolicyDocumentLabels[policyType].replace('LEGAL_BRAND ', '');
  }

  return LegalPolicyDocumentLabels[policyType];
};

// ==============================
// COMPONENTS
// ==============================
const ExpiredInvitation = () => (
  <div className="NewUser-invitation-not-found">
    <FarmbotLogoHorizontal style={{ maxWidth: 440, margin: '2rem' }} />
    <Typography.Title level={2}>Invitation not found</Typography.Title>
    <Typography.Title level={5} type="secondary" style={{ marginTop: 0 }}>
      Please make sure the invitation is not expired.
    </Typography.Title>
  </div>
);

const LegalPolicyDisclaimer = ({
  documents
}: {
  documents: LegalPolicyDocument[]
}) => {
  const { title: whiteLabel } = getWhiteLabelConfig();

  return (
    <span>
      By continuing, I accept {whiteLabel}'s{' '}
      {documents.map((document, index) => {
        return (
          <span key={index}>
            {index > 0 && (index < documents.length - 1 ? ', ' : ' and ')}
            <Link
              to={document.externalLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getDocumentLabel(document.policyType)}
            </Link>
          </span>
        );
      })}
      .
    </span>
  );
};

export default function NewUser() {
  const dispatch = useDispatch();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [form] = useForm();
  const { token } = useParams() as { token: string };

  const isInvitationLoading = useIsLoading(invitationActions);
  const isAddUserLoading = useIsLoading(addUserActions);
  const documents = useLegalPolicyDocumentsAsArray();

  const [invitation, setInvitation] = useState<Invitation | undefined>();
  const [email, setEmail] = useState<string>('');
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(true);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);

  useEffect(() => {
    try {
      const { id } = JSON.parse(atob(token));
      dispatch(
        loadInvitation(id, (i) => {
          setEmail(i.email);
          setIsNewCustomer(i.isNewCustomer);
          setInvitation(i);
        })
      );
    } catch {
      setEmail('');
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (isAuthenticated || !isNewCustomer) {
      // Go to invitation list
      loginWithRedirect({
        appState: { target: `${window.location.origin}/enterprises` }
      });
    }
  }, [isAuthenticated, isNewCustomer, loginWithRedirect]);

  useEffectOnce(() => {
    dispatch(fetchLegalPolicyDocuments());
  });

  const submit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        // Flow: Sign up -> enterprise/invitation list -> Auth0 sign in
        // -> enterprise/invitation list
        dispatch(
          addUser({ ...values, invitation }, () =>
            loginWithRedirect({
              appState: { target: `${window.location.origin}/enterprises` },
              authorizationParams: { login_hint: email }
            })
          )
        );
      })
      .catch(() => { }); // Ignore promise rejection.
  }, [dispatch, email, form, invitation, loginWithRedirect]);

  // If user is authenticated, loading the spinner while redirecting
  if (isInvitationLoading || isAuthenticated) {
    return <Suspense fallback={<LoadingSpinner useLogo />} />;
  }

  if (!email) return <ExpiredInvitation />;

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <HelmetWrapper section="Profile Set Up" />
      <DefaultPageHeader title="Profile Set Up" />
      <PageHeaderPusher>
        <div className="NewUser">
          <Typography.Title level={5}>Profile Set Up</Typography.Title>
          <DefaultForm
            form={form}
            layout="vertical"
            name="newUser"
            initialValues={{
              enableEmailNotifications: true,
              enableSmsNotifications: true
            }}
            onFinish={submit}
            requiredMark="optional"
          >
            <DefaultCard className="NewUser-card">
              <UserFormItems isNewUser />
            </DefaultCard>
            <DefaultCard className="NewUser-card">
              <PasswordFormItems />
              <Divider />
              <div className="NewUser-footer">
                {!documents ? <LoadingSpinner /> : (
                  <>
                    <div className="NewUser-footer-left">
                      <DefaultCheckbox checked={isAccepted} onChange={() => setIsAccepted(!isAccepted)} />
                      <LegalPolicyDisclaimer documents={documents} />
                    </div>
                    <DefaultButton
                      type="primary"
                      htmlType="submit"
                      className="NewUser-footer-button"
                      onClick={submit}
                      loading={isAddUserLoading}
                      disabled={!isAccepted}
                    >
                      Submit
                    </DefaultButton>
                  </>
                )}
              </div>
            </DefaultCard>
          </DefaultForm>
        </div>
      </PageHeaderPusher>
    </ErrorBoundary>
  );
}
