/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-types */
import { LoadingOutlined } from '@ant-design/icons';
import { ConfigProvider, List, Table, Typography } from 'antd';
import { ListProps } from 'antd/lib/list';
import { ColumnType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Resizable } from 'react-resizable';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { PAGE_SIZE } from 'constants/list';
import { SEARCH_PARAMS } from 'constants/search-params';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import { LEGACY_MAX_WIDTH_MOBILE_LANDSCAPE } from 'style/constants';
import classNamesWithBase from 'utils/classnames-with-base';

import './styles.less';

const ResizableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;

  if (!width || !onResize) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

interface Props<RecordType>
  extends Pick<
    TableProps<RecordType>,
    | 'className'
    | 'components'
    | 'bordered'
    | 'expandable'
    | 'rowKey'
    | 'dataSource'
    | 'onRow'
    | 'loading'
    | 'scroll'
    | 'size'
    | 'sticky'
    | 'rowClassName'
  > {
  columns: ColumnType<RecordType>[];
  empty: React.ReactNode;
  mobileListTitleRight?: string;
  mobileListTitleLeft?: string;
  /**
   * Item renderer on mobile view; be sure to pass your own onClick, etc. as needed because it does not
   * by default receive the onRow props from the parent DefaultTable component
   */
  mobileListItem?: ListProps<RecordType>['renderItem'];
  rowSelection?: any;
  /**
   * Pagination props
   */
  pageSize?: number;
  total?: number;
  /**
   * Before implementing this callback,
   * please check the `onChange` in the pagination property of DefaultTable.
   */
  onPaginationChange?: (total: number, from: number, to: number) => void;
  paginationPositions?: Required<TablePaginationConfig>['position'];
  disablePagination?: boolean;
  forceDesktopView?: boolean;
  mobileViewMaxWidth?: number;
  // TODO: remove this prop after https://farmbot.atlassian.net/browse/FMBT-6455, and
  // https://farmbot.atlassian.net/browse/FMBT-6453
  /**
   * Components on old React Router are not using 'useApplyCachedSearchParams' yet,
   * and still rely on the dispatch(appendValuesToQueryString) method.
   */
  isOnOldRouter?: boolean;
}

/**
 * A wrapper around the antd Table with our custom config applied.
 * Includes the following:
 * 1. Custom empty message.
 * 2. Hiding pagination if only 1 page exists.
 * 3. Storing currentPage in query string.
 */
function DefaultTable<RecordType extends object>({
  bordered,
  className,
  components = {
    header: {
      cell: ResizableTitle
    }
  },
  expandable,
  rowKey,
  columns,
  dataSource,
  onRow,
  empty,
  loading,
  // Enables horizontal scroll when the table overflows.
  scroll = { x: 0 },
  size = 'large',
  mobileListTitleRight,
  mobileListTitleLeft,
  mobileListItem,
  pageSize = PAGE_SIZE.DEFAULT_TABLE,
  total,
  onPaginationChange,
  paginationPositions = ['bottomRight'],
  sticky,
  rowSelection,
  rowClassName,
  disablePagination = false,
  mobileViewMaxWidth = LEGACY_MAX_WIDTH_MOBILE_LANDSCAPE,
  isOnOldRouter
}: Props<RecordType>): JSX.Element {
  const isTabletOrMobile = useMediaQuery({ maxWidth: mobileViewMaxWidth });
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage: number = Number(searchParams.get(SEARCH_PARAMS.TABLE.CURRENT_PAGE)) || 1;

  const sortField: string | undefined = searchParams.get(SEARCH_PARAMS.TABLE.SORT_FIELD) || undefined;
  const sortOrder: 'descend' | 'ascend' | undefined = (
    searchParams.get(SEARCH_PARAMS.TABLE.SORT_ORDER) || undefined
  ) as 'descend' | 'ascend' | undefined;

  const dispatch = useDispatch();

  useEffect(() => {
    if (onPaginationChange) {
      const from = Math.min(
        Math.max((currentPage - 1) * pageSize + 1, 1),
        total || 0
      );
      const to = Math.min(currentPage * pageSize, total || 0);
      onPaginationChange(total || 0, from, to);
    }
  }, [onPaginationChange, pageSize, total, currentPage]);

  const rowClassNameHandler = useCallback(
    (record, index, indent) => {
      let actualRowClassName = 'cursor-pointer ';
      if (rowClassName) {
        actualRowClassName +=
          typeof rowClassName === 'string'
            ? rowClassName
            : rowClassName(record, index, indent);
      }
      return actualRowClassName;
    },
    [rowClassName]
  );

  const formattedColumns = useMemo(
    () =>
      columns?.map((column) => {
        const columnCopy = { ...column };
        const { key, dataIndex } = column;
        const columnId = key || dataIndex;
        if (columnId === sortField) {
          columnCopy.sortOrder = sortOrder;
        }
        return columnCopy;
      }),
    [columns, sortField, sortOrder]
  );

  const pagination = useMemo(() => {
    const totalRecords = total || dataSource?.length || 0;
    const hidePagination = disablePagination || totalRecords <= pageSize;
    if (hidePagination) return false;
    return {
      position: paginationPositions,
      current: currentPage || 1,
      total,
      onChange: (page) => {
        // Scroll to top on page change
        window.scrollTo(0, 0);

        if (isOnOldRouter) {
          dispatch(appendValuesToQueryString({ currentPage: page }));
          return;
        }
        setSearchParams((prev) => {
          const newSearchParams = new URLSearchParams(prev);
          newSearchParams.set(SEARCH_PARAMS.TABLE.CURRENT_PAGE, page.toString());
          return newSearchParams;
        });
      },
      pageSize,
      showSizeChanger: false,
      style: {
        marginRight: isTabletOrMobile ? 10 : undefined
      }
    };
  }, [
    currentPage,
    dataSource?.length,
    disablePagination,
    dispatch,
    isOnOldRouter,
    isTabletOrMobile,
    pageSize,
    paginationPositions,
    setSearchParams,
    total
  ]);

  const DesktopTable = useCallback(
    (forMobilePrinting?: boolean) => (
      <ConfigProvider renderEmpty={() => empty}>
        <Table
          className={classNamesWithBase(
            'DefaultTable',
            {
              'mobile-print': !!forMobilePrinting,
              'with-pagination': !!pagination
            },
            className
          )}
          bordered={bordered}
          expandable={expandable}
          rowKey={rowKey}
          columns={formattedColumns}
          components={components}
          dataSource={dataSource}
          showSorterTooltip={false}
          loading={
            loading
              ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
              : false
          }
          scroll={scroll}
          pagination={pagination}
          rowClassName={rowClassNameHandler}
          size={size}
          onRow={onRow}
          onChange={(_pagination, _filters, sorter) => {
            if (!Array.isArray(sorter)) {
              const { order, columnKey, field } = sorter;
              const key = columnKey || field;
              if (sortOrder !== order || sortField !== key) {
                if (isOnOldRouter) {
                  dispatch(
                    appendValuesToQueryString({
                      sortOrder: order,
                      sortField: key,
                      // Reset page when sorting
                      currentPage: 1
                    })
                  );
                  return;
                }
                setSearchParams((prev) => {
                  const newSearchParams = new URLSearchParams(prev);

                  if (order) newSearchParams.set(SEARCH_PARAMS.TABLE.SORT_ORDER, order);
                  else newSearchParams.delete(SEARCH_PARAMS.TABLE.SORT_ORDER);

                  if (key) newSearchParams.set(SEARCH_PARAMS.TABLE.SORT_FIELD, key.toString());
                  else newSearchParams.delete(SEARCH_PARAMS.TABLE.SORT_FIELD);

                  newSearchParams.set(SEARCH_PARAMS.TABLE.CURRENT_PAGE, '1'); // Reset page when sorting
                  return newSearchParams;
                });
              }
            }
          }}
          sticky={sticky}
          rowSelection={rowSelection}
        />
      </ConfigProvider>
    ),
    [
      bordered,
      className,
      components,
      dataSource,
      dispatch,
      empty,
      expandable,
      formattedColumns,
      isOnOldRouter,
      loading,
      onRow,
      pagination,
      rowClassNameHandler,
      rowKey,
      rowSelection,
      scroll,
      size,
      setSearchParams,
      sortField,
      sortOrder,
      sticky
    ]
  );

  const MobileListHeader = () => {
    return (
      <div className="DefaultTable-mobile-header">
        <Typography.Text>{mobileListTitleLeft}</Typography.Text>
        <Typography.Text>{mobileListTitleRight}</Typography.Text>
      </div>
    );
  };

  // On mobile it's a list with a hidden table component for printing; On desktop
  // it's table only.
  if (isTabletOrMobile && mobileListItem) {
    return (
      <>
        {DesktopTable(true)}
        <List
          className="DefaultTable-mobile-list"
          // TODO: handle dataSource readonly properly
          dataSource={dataSource ? [...dataSource] : dataSource}
          locale={{ emptyText: empty }}
          header={
            (mobileListTitleLeft || mobileListTitleRight) && (
              <MobileListHeader />
            )
          }
          renderItem={mobileListItem}
        />
      </>
    );
  }

  return DesktopTable();
}

export default DefaultTable;
