import { ColumnType } from 'antd/lib/table';
import React, {
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResizeCallbackData } from 'react-resizable';
import { useMeasure } from 'react-use';
import { AnyAction } from 'redux';

import AlertWithOfflineResult from 'components/atoms/AlertWithOfflineResult';
import DefaultTable from 'components/composites/DefaultTable';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import { makeSelectFirstErrorMessage } from 'store/modules/error/selectors';
import { makeSelectIsLoading } from 'store/modules/loading/selectors';

import './styles.less';

const HEADER_HEIGHT = 55;
const PAGINATION_HEIGHT = 68;

type BaseTableDataType = { id: number | string };

export interface Props<T extends BaseTableDataType> {
  columns: ColumnType<T>[];
  data: T[];
  total?: number;
  loadActionType: string;
  rowIdToOnClickAction: (id: T['id']) => AnyAction;
  onPaginationChange?: (total: number, from: number, to: number) => void;
  emptyText: string;
  // This may need to change when we implement bulk editing
  selectedRowId?: number;
  rowSelection?: any;
}

function BackOfficeTable<T extends BaseTableDataType>({
  columns,
  data,
  total,
  loadActionType,
  rowIdToOnClickAction,
  onPaginationChange,
  emptyText,
  selectedRowId,
  rowSelection
}: Props<T>): JSX.Element {
  const dispatch = useDispatch();

  const selectIsLoading = useMemo(
    () => makeSelectIsLoading([loadActionType]),
    [loadActionType]
  );
  const selectErrorMessage = useMemo(
    () => makeSelectFirstErrorMessage([loadActionType]),
    [loadActionType]
  );
  const isLoading = useSelector(selectIsLoading);
  const errorMessage = useSelector(selectErrorMessage);

  const [innerColumns, setInnerColumns] = useState(columns);

  const [ref, { width, height }] = useMeasure<HTMLDivElement>();

  const scroll = useMemo(
    () => ({
      x: width - 2,
      y: height - HEADER_HEIGHT - PAGINATION_HEIGHT
    }),
    [width, height]
  );

  const handleOnRow = useCallback(
    (row) => {
      if (row) {
        return {
          onClick: () => dispatch(rowIdToOnClickAction(row.id))
        };
      }
      return {};
    },
    [dispatch, rowIdToOnClickAction]
  );

  const rowClassNameHandler = useCallback(
    (row) => {
      if (row) {
        return selectedRowId === row.id ? 'selected' : '';
      }
      return '';
    },
    [selectedRowId]
  );

  const handleResize = useCallback(
    (index: number) =>
      (_event: SyntheticEvent, { size }: ResizeCallbackData) =>
        setInnerColumns((previousColumns) => {
          const nextColumns = [...previousColumns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width
          };
          return nextColumns;
        }),
    []
  );

  const resizableColumns: ColumnType<T>[] = useMemo(() =>
    innerColumns.map<ColumnType<T>>((column, index) => ({
      ...column,
      onHeaderCell: (col: ColumnType<T>) =>
        // TODO: Old "WIP" from 3 years ago, need to revise properly
        // @ts-ignore
        ({
          width: col.width,
          onResize: handleResize(index)
        }) as HTMLAttributes<HTMLElement>
    })
    ), [handleResize, innerColumns]);

  if (isLoading && typeof total === 'undefined') {
    return <LoadingSpinner />;
  }

  if (errorMessage) {
    return <AlertWithOfflineResult errorMessage={errorMessage} />;
  }

  return (
    <div ref={ref} className="BackOfficeTable">
      <DefaultTable<T>
        isOnOldRouter // TODO: Remove this prop in https://farmbot.atlassian.net/browse/FMBT-6453
        rowClassName={rowClassNameHandler}
        bordered
        rowKey="id"
        columns={resizableColumns}
        dataSource={data}
        scroll={scroll}
        empty={emptyText}
        total={total}
        onPaginationChange={onPaginationChange}
        loading={isLoading}
        onRow={handleOnRow}
        rowSelection={rowSelection}
      />
    </div>
  );
}

export default BackOfficeTable;
