import isNil from 'lodash/isNil';

import { TANK_LEVEL_PRECISION } from 'constants/samplePoint';
import Sample from 'types/models/sample';
import { DataType } from 'types/sample.enum';
import { convertMetricToImperialAlwaysRounding } from 'utils/convert-metric-to-imperial';
import { Quantity, Reading } from 'utils/convert-unit';
import { UnitType, getUnitByCountry } from 'utils/get-unit-by-country';
import { millisecondsToSeconds } from 'utils/unit-conversions';

/**
 * Forecasts a tank's liquid level at a given future time based on a past sample's date, value, and gradient.
 *
 * @param baseSample - The past sample containing the liquid level (in cm), the timestamp,
 * and the rate of change (per minute).
 * @param forecastDateInSeconds - The future date (in seconds) for which the forecast is to be made.
 * @param tankHeightMetric - The maximum height of the tank in the metric unit system (optional).
 * @param country - The country code to determine the unit system (default is 'AUS').
 * @returns The forecasted liquid level in centimetres or inches, constrained by the tank height if provided, and
 * rounded per the unit system's precision. A metric value is also returned in case it is needed for further
 * calculations.
 */
export function getTankLevelForecast(
  baseSample: Sample,
  forecastDateInSeconds: number,
  tankHeightMetric?: number,
  country = 'AUS'
): Reading | undefined {
  if (baseSample.dataType !== DataType.FORECAST) return undefined;

  const changePerMinute = baseSample.extraValues?.gradient;
  if (isNil(changePerMinute)) return undefined;

  const unitSymbolLocale = getUnitByCountry<UnitType.LEVEL_SM>({ unitType: UnitType.LEVEL_SM, country });

  if (changePerMinute === 0) {
    const readingMetric: Quantity = {
      value: baseSample.rwValue,
      unit: { symbol: 'cm' }
    };
    const readingLocale: Quantity = {
      value: convertMetricToImperialAlwaysRounding(
        UnitType.LEVEL_SM,
        country,
        baseSample.rwValue,
        TANK_LEVEL_PRECISION
      )!, // Won't be undefined because baseSample.rwValue is not undefined
      unit: { symbol: unitSymbolLocale }
    };
    return {
      metric: readingMetric,
      locale: readingLocale
    };
  }

  const minutesUntilForecast: number = (forecastDateInSeconds - millisecondsToSeconds(baseSample.date)) / 60;
  const forecastedValueMetric: number = baseSample.rwValue + minutesUntilForecast * changePerMinute;

  const forecastedValueLocale: number = convertMetricToImperialAlwaysRounding(
    UnitType.LEVEL_SM,
    country,
    forecastedValueMetric,
    TANK_LEVEL_PRECISION
  )!; // Won't be undefined because forecastedValueMetric is not undefined

  if (tankHeightMetric && forecastedValueMetric > tankHeightMetric) {
    return {
      metric: {
        value: tankHeightMetric,
        unit: { symbol: 'cm' }
      },
      locale: {
        value: convertMetricToImperialAlwaysRounding(
          UnitType.LEVEL_SM,
          country,
          tankHeightMetric,
          TANK_LEVEL_PRECISION
        )!, // Won't be undefined because forecastedValueMetric is not undefined
        unit: { symbol: unitSymbolLocale }
      }
    };
  }
  return {
    metric: {
      value: Math.max(forecastedValueMetric, 0),
      unit: { symbol: 'cm' }
    },
    locale: {
      value: Math.max(forecastedValueLocale, 0),
      unit: { symbol: unitSymbolLocale }
    }
  };
}