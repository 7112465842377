import { Space } from 'antd';
import React from 'react';

import SearchInput from 'components/atoms/SearchInput';
import BackOfficeFilterDrawerTriggerButton from 'components/buttons/BackOfficeFilterDrawerTriggerButton';
import { FilterSetName } from 'store/modules/routerUtils/types';

import './styles.less';

interface Props {
  children: React.ReactNode;
  actions: React.ReactNode;
  searchInputPlaceholder?: string;
  paginationText?: string;
  filterSetName?: FilterSetName;
}

function BackOfficeActionsBar({
  children,
  actions,
  searchInputPlaceholder,
  paginationText,
  filterSetName
}: Props): JSX.Element | null {
  return (
    <div className="BackOfficeActionsBar-wrapper">
      <div className="BackOfficeActionsBar-container">
        <Space>
          <SearchInput
            isOnOldRouter // TODO: remove this prop in https://farmbot.atlassian.net/browse/FMBT-6453
            placeholder={searchInputPlaceholder} autoFocusOnLoad
          />
          {filterSetName && (
            <BackOfficeFilterDrawerTriggerButton
              filterSetName={filterSetName}
            />
          )}
          {paginationText}
        </Space>
        <div className="BackOfficeActionsBar-buttons">{actions}</div>
      </div>
      <div className="BackOfficeActionsBar-pusher">{children}</div>
    </div>
  );
}

export default BackOfficeActionsBar;
