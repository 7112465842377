import antdMessage from 'antd/lib/message';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { legalPolicyClient } from 'clients';
import { GetLegalPolicyUserAcceptanceDto, SetLegalPolicyUserAcceptanceDto } from 'types/dto/legal-policy-user-acceptance.dto';
import { LegalBrand } from 'types/legal-brand.enum';
import { LegalPolicyDocument } from 'types/models/legal-policy-document';
import { APIResponseParsed } from 'types/response';
import { isFarmbot } from 'utils/is-x-bot';

import { acceptLegalPoliciesFailure, acceptLegalPoliciesSuccess, fetchLegalPolicyDocumentsFailure, fetchLegalPolicyDocumentsSuccess, fetchLegalPolicyUserAcceptanceFailure, fetchLegalPolicyUserAcceptanceSuccess, setLastChecked, setLegalPolicyDocuments, setLegalPolicyUserAcceptance } from './actions';
import { LegalPolicyActionType } from './constants';

// ==============================
// PURE FUNCTIONS
// ==============================
const getLegalBrand = (isFarmbotDomain: boolean): LegalBrand =>
  isFarmbotDomain ? LegalBrand.FARMBOT : LegalBrand.RANCHBOT;

// ==============================
// SAGAS
// ==============================
function* requestLatestPolicyDocuments() {
  const legalBrand: LegalBrand = getLegalBrand(isFarmbot());

  const response: APIResponseParsed<LegalPolicyDocument[]> = yield call(
    legalPolicyClient.fetchLatestLegalPolicyDocuments,
    legalBrand
  );

  if (response.data) {
    yield all([
      put(setLegalPolicyDocuments(response.data)),
      put(fetchLegalPolicyDocumentsSuccess())
    ]);
  } else {
    const message = response.error.message || 'Unable to fetch legal policy documents';
    yield put(fetchLegalPolicyDocumentsFailure(message));
  }
}

function* requestUserPolicyAcceptance() {
  const legalBrand: LegalBrand = getLegalBrand(isFarmbot());

  const response: APIResponseParsed<GetLegalPolicyUserAcceptanceDto> = yield call(
    legalPolicyClient.fetchLegalPolicyUserAcceptanceStatus,
    legalBrand
  );

  if (response.data) {
    yield all([
      put(setLegalPolicyUserAcceptance(response.data.userId, !response.data.hasPendingUnacceptedDocuments)),
      put(setLegalPolicyDocuments(response.data.latestLegalPolicyDocuments)),
      put(setLastChecked(new Date().getTime())),
      put(fetchLegalPolicyUserAcceptanceSuccess())
    ]);
  } else {
    const message = response.error.message || 'Unable to fetch legal policy user acceptance status';
    yield put(fetchLegalPolicyUserAcceptanceFailure(message));
  }
}

function* requestAcceptLegalPolicy() {
  const legalBrand: LegalBrand = getLegalBrand(isFarmbot());

  const response: APIResponseParsed<SetLegalPolicyUserAcceptanceDto> = yield call(
    legalPolicyClient.acceptLatestLegalPolicies,
    legalBrand
  );

  if (response.data) {
    yield all([
      put(setLegalPolicyUserAcceptance(response.data.userId, !response.data.hasPendingUnacceptedDocuments)),
      put(setLastChecked(new Date().getTime())),
      put(acceptLegalPoliciesSuccess())
    ]);

    antdMessage.success('Policies accepted');
  } else {
    const message = response.error.message || 'Unable to accept legal policy';
    yield put(acceptLegalPoliciesFailure(message));
  }
}

// ==============================
// REGISTRATION
// ==============================
function* watchFetchLegalPolicyDocumentsRequest() {
  yield takeLatest(LegalPolicyActionType.FETCH_LEGAL_POLICY_DOCUMENTS_REQUEST, requestLatestPolicyDocuments);
}

function* watchFetchLegalPolicyUserAcceptanceRequest() {
  yield takeLatest(LegalPolicyActionType.FETCH_LEGAL_POLICY_USER_ACCEPTANCE_REQUEST, requestUserPolicyAcceptance);
}

function* watchAcceptLegalPolicyRequest() {
  yield takeLatest(LegalPolicyActionType.ACCEPT_LEGAL_POLICY_REQUEST, requestAcceptLegalPolicy);
}

// ==============================
// EXPORT
// ==============================
export default function* legalPolicySaga() {
  yield all([
    fork(watchFetchLegalPolicyDocumentsRequest),
    fork(watchFetchLegalPolicyUserAcceptanceRequest),
    fork(watchAcceptLegalPolicyRequest)
  ]);
}