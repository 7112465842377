import { AssetTypeCode } from 'types/models/asset-type';

import { SEARCH_PARAMS } from './search-params';

// Prefixing the index routePath with an underscore so that we do not sort it alphabetically like the other routePaths.
export const ROUTE_PATHS = {
  enterprise: {
    account: {
      _index: '/enterprise/account',
      details: '/enterprise/account/details',
      monitors: {
        _index: '/enterprise/account/monitors',
        detail: '/enterprise/account/monitors/:id',
        buildDetailPath: (id: number) => `/enterprise/account/monitors/${id}`
      },
      profileSettings: '/enterprise/account/profile-settings',
      sites: '/enterprise/account/sites',
      teamMembers: '/enterprise/account/team-members',
      teamNotificationManagement: {
        _index: '/enterprise/account/team-notification-management',
        monitors: `/enterprise/account/team-notification-management?${SEARCH_PARAMS.TAB}=monitors`,
        thirdParty: `/enterprise/account/team-notification-management?${SEARCH_PARAMS.TAB}=third-party`
      }
    },
    assetType: {
      _index: '/enterprise/asset-type',
      assetTypeId: '/enterprise/asset-type/:assetTypeId',
      buildAssetTypeIdPath: (assetTypeId: AssetTypeCode) => `/enterprise/asset-type/${assetTypeId}`,
      buildSamplePointIdPath: (assetTypeId: AssetTypeCode, samplePointId: number) => `/enterprise/asset-type/${assetTypeId}/${samplePointId}`
    },
    ciboLocation: {
      locationDetail: '/enterprise/cibo-location/:ciboLocationId',
      buildLocationDetailPath: (ciboLocationId: number) => `/enterprise/cibo-location/${ciboLocationId}`
    },
    more: {
      _index: '/enterprise/more'
    },
    samplePoints: {
      alerts: {
        _index: '/enterprise/sample-points/alerts',
        newNotifications: '/enterprise/sample-points/alerts/new-notifications',
        all: '/enterprise/sample-points/alerts/all'
      },
      mapView: '/enterprise/sample-points/map-view'
    }
  }
};