/**
 * Sagas for providing utilities for manipulating router store.
 */

import { push } from 'connected-react-router';
import queryString from 'query-string';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  appendValuesToQueryString,
  storeSearch
} from './actions';
import ActionTypes from './constants';
import {
  selectPathname,
  selectRouterQueryString
} from './selectors';

/**
 * Allows us to append values to an existing query string without overriding
 * other values and cut the need for a component to know about all query string
 * parameters in order to update it thus preventing unnecessary re-renders.
 */
export function* appendValuesToQueryStringSaga({
  payload
}: ReturnType<typeof appendValuesToQueryString>) {
  const pathname: string = yield select(selectPathname);
  const routerQueryString: ReturnType<typeof selectRouterQueryString> =
    yield select(selectRouterQueryString);

  const search = queryString.stringify(
    {
      ...routerQueryString,
      ...payload
    },
    { arrayFormat: 'bracket' }
  );

  yield all([
    put(
      push({
        search
      })
    ),
    put(storeSearch(pathname, search))
  ]);
}

export function* watchAppendValuesToQueryString() {
  yield takeEvery(
    ActionTypes.APPEND_VALUES_TO_QUERY_STRING,
    appendValuesToQueryStringSaga
  );
}

export default function* routerSaga() {
  yield all([
    fork(watchAppendValuesToQueryString)
  ]);
}
