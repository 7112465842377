import moment from 'moment-timezone';

import { UnitOfTime } from 'types/models/time';

import { DateRangeExtensionOptions } from '../use-sample-chart-date-range';

// Do this in each file using moment().startOf(week) or endOf(week) to set the first day of the week to Monday
moment.updateLocale('en', { week: { dow: 1 } });

/**
 * Takes a near end date and returns the start and end timestamp for a given date range type.
 * @param nearEndDateTimestamp
 * @param unitOfTime
 * @param amountOfTime
 * @param timezoneCode
 * @param extensionOptions Options to extend the start or the end date by one day,
 * in case a sample started or ended outside the date range.
 * @param extendOptions.extendStartByOneDay
 * @param extendOptions.extendEndByOneDay
 * @returns
 */
function getDateRange(
  nearEndDateTimestamp: number | undefined,
  unitOfTime: UnitOfTime,
  amountOfTime: number,
  timezoneCode?: string,
  extensionOptions?: DateRangeExtensionOptions
): { start: number; end: number } {
  let nearEndDate;

  if (nearEndDateTimestamp && timezoneCode) {
    nearEndDate = moment(nearEndDateTimestamp).tz(timezoneCode);
  } else if (timezoneCode) {
    nearEndDate = moment.tz(timezoneCode);
  } else if (nearEndDateTimestamp) {
    nearEndDate = moment(nearEndDateTimestamp);
  } else {
    nearEndDate = moment();
  }

  const startDate = nearEndDate
    .clone()
    .subtract(amountOfTime - 1, unitOfTime)
    .startOf(unitOfTime);
  const endDate = nearEndDate.clone().endOf(unitOfTime);

  const { extendStartByOneDay = false, extendEndByOneDay = false } = extensionOptions || {};
  if (extendStartByOneDay) startDate.subtract(1, 'day');
  if (extendEndByOneDay) endDate.add(1, 'day');

  return {
    start: startDate.valueOf(),
    end: endDate.valueOf()
  };
}

export default getDateRange;
