import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  makeSelectRouterQueryStringFilterSet,
  makeSelectStoredSearch
} from './selectors';
import { FilterSetName } from './types';

export function useStoredSearch(pathname: string): string {
  const selectStoredSearch = useMemo(
    () => makeSelectStoredSearch(pathname),
    [pathname]
  );

  const storedSearch = useSelector(selectStoredSearch, shallowEqual);

  return storedSearch;
}

export function useRouterQueryStringFilterSet(filterSetName: FilterSetName) {
  const selectRouterQueryStringFilterSet = useMemo(
    () => makeSelectRouterQueryStringFilterSet(filterSetName),
    [filterSetName]
  );

  const routerQueryStringFilterSet = useSelector(
    selectRouterQueryStringFilterSet,
    isEqual
  );

  return routerQueryStringFilterSet;
}
