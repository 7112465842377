import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import SamplePoint, { SamplePointId } from 'types/models/samplePoint';

import ActionTypes from './constants';

export const setSamplePoints = (
  samplePoints: Record<SamplePointId, SamplePoint>
) => action(ActionTypes.SET_SAMPLE_POINTS, samplePoints);

export const setSamplePoint = (samplePoint: SamplePoint) =>
  action(ActionTypes.SET_SAMPLE_POINT, samplePoint);

/**
 * editSamplePoint
 * @param samplePoint
 * @param values sample point config
 * @param onSuccess A function to override the default success toast
 * @returns
 */
export const editSamplePoint = (
  samplePoint: SamplePoint,
  values: Partial<SamplePoint>,
  onSuccess?: () => void
) =>
  action(ActionTypes.EDIT_SAMPLE_POINT_REQUEST, {
    samplePoint,
    values,
    onSuccess
  });

export const editSamplePointSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_SAMPLE_POINT_SUCCESS, { response });

export const editSamplePointFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.EDIT_SAMPLE_POINT_FAILURE, { message, error });

export const removeSamplePoint = (ids: number[]) =>
  action(ActionTypes.REMOVE_SAMPLE_POINT, {
    ids
  });

export const removeSamplePointRequest = (samplePoint: SamplePoint, backToSamplePointListPage: () => void) =>
  action(ActionTypes.REMOVE_SAMPLE_POINT_REQUEST, {
    samplePoint,
    backToSamplePointListPage
  });

export const removeSamplePointSuccess = (response: AxiosResponse) =>
  action(ActionTypes.REMOVE_SAMPLE_POINT_SUCCESS, { response });

export const removeSamplePointFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.REMOVE_SAMPLE_POINT_FAILURE, { message, error });
