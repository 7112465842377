import { message as antdMessage } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  selectCurrentEnterpriseBillingAddress,
  selectCurrentEnterpriseCountry,
  selectCurrentEnterpriseId
} from 'store/modules/enterprise/selectors';
import trackEvent from 'store/modules/tracking/actions';
import { EventType } from 'store/modules/tracking/types';
import HttpStatusCode from 'types/http-status-code.enum';
import Address from 'types/models/address';
import { EnterpriseId } from 'types/models/enterprise';
import Site, { SiteId } from 'types/models/site';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest
} from 'utils/redux-saga-requests';

import {
  addSite,
  addSiteFailure,
  addSiteSuccess,
  deleteSite,
  deleteSiteFailure,
  deleteSiteSuccess,
  editSite,
  editSiteFailure,
  editSitePreference,
  editSitePreferenceFailure,
  editSitePreferenceSuccess,
  editSiteSuccess,
  loadSites,
  loadSitesFailure,
  loadSitesSuccess,
  setSite,
  setSites,
  unsetSite
} from './actions';
import ActionTypes from './constants';
import parseSiteFromPayload, {
  parseSiteToBackOfficeSite,
  parseSites
} from './sagaHelper';
import { makeSelectSiteById } from './selectors';
import { loadSamplePointsStatistics } from '../samplePointsStatistics/actions';

export function* requestLoadSites(
  action: ReturnType<typeof loadSites>
): SagaIterator {
  const {
    payload: { enterpriseId }
  } = action;

  try {
    const response = yield call(getRequest, 'site', {
      params: {
        filter: `enterpriseId::eq::${enterpriseId}`
      }
    });
    const { data } = response;
    const country = yield select(selectCurrentEnterpriseCountry);
    const parsedSites = parseSites(data, country);
    const sitesDict = keyBy(parsedSites, 'id');
    yield all([put(loadSitesSuccess(data)), put(setSites(sitesDict))]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadSitesFailure(message, error));
  }
}

export function* watchLoadSitesRequest() {
  yield takeLatest(ActionTypes.LOAD_SITES_REQUEST, requestLoadSites);
}

export function* requestAddSite(
  action: ReturnType<typeof addSite>
): SagaIterator {
  const {
    payload: { values, onSuccess }
  } = action;
  const enterpriseId: EnterpriseId = yield select(selectCurrentEnterpriseId);
  const enterpriseBillingAddress: Address = yield select(
    selectCurrentEnterpriseBillingAddress
  );

  try {
    const sitePayload = parseSiteFromPayload(
      null,
      values,
      enterpriseId,
      enterpriseBillingAddress
    );

    const response: AxiosResponse = yield call(postRequest, 'site', {
      ...sitePayload,
      enterpriseId
    });
    const { data } = response;
    const parsedSite = parseSiteToBackOfficeSite(
      data,
      enterpriseBillingAddress,
      values.useEnterpriseBilling
    );
    yield all([
      put(loadSamplePointsStatistics(enterpriseId)),
      put(setSite(parsedSite)),
      put(addSiteSuccess(response)),
      put(
        trackEvent({
          type: EventType.SITE_CREATED,
          data: {
            siteId: parsedSite.id,
            status: true
          }
        })
      )
    ]);
    antdMessage.success('Site added');
    if (onSuccess) onSuccess();
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to add site');
    yield all([
      put(addSiteFailure(message, error)),
      put(
        trackEvent({ type: EventType.SITE_CREATED, data: { status: false } })
      )
    ]);
  }
}

export function* watchAddSiteRequest() {
  yield takeLatest(ActionTypes.ADD_SITE_REQUEST, requestAddSite);
}

export function* requestEditSite(
  action: ReturnType<typeof editSite>
): SagaIterator {
  const {
    payload: { siteId, values }
  } = action;
  const enterpriseId: EnterpriseId = yield select(selectCurrentEnterpriseId);
  const enterpriseBillingAddress: Address = yield select(
    selectCurrentEnterpriseBillingAddress
  );
  try {
    const sitePayload = parseSiteFromPayload(
      siteId,
      values,
      enterpriseId,
      enterpriseBillingAddress
    );
    const siteResponse: AxiosResponse = yield call(patchRequest, `site/${siteId}`, sitePayload);
    const { data } = siteResponse;
    const parsedSite = parseSiteToBackOfficeSite(
      data,
      enterpriseBillingAddress,
      values.useEnterpriseBilling
    );
    yield all([
      put(loadSamplePointsStatistics(enterpriseId)),
      put(setSite(parsedSite)),
      put(editSiteSuccess(siteResponse))
    ]);
    antdMessage.success('Site edited');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to edit site');
    yield put(editSiteFailure(message, error));
  }
}

export function* watchEditSiteRequest() {
  yield takeLatest(ActionTypes.EDIT_SITE_REQUEST, requestEditSite);
}

function* handleEditSitePreferenceSuccess(
  siteId: SiteId,
  response: AxiosResponse
): SagaIterator {
  const site: Site = yield select(makeSelectSiteById(siteId));
  const { data: updatedPreference } = response;
  yield all([
    put(setSite({ ...site, userSitePreference: updatedPreference })),
    put(editSitePreferenceSuccess(response))
  ]);
  antdMessage.success('Preference updated');
}

function* handleEditSitePreferenceFailure(error: AxiosError): SagaIterator {
  const message = get(
    error,
    'response.data.message',
    'Sorry, something went wrong.'
  );
  antdMessage.error('Failed to update preference');
  yield put(editSitePreferenceFailure(message, error));
}

export function* requestEditSitePreference(
  action: ReturnType<typeof editSitePreference>
): SagaIterator {
  const {
    payload: { siteId, preference }
  } = action;
  const { id, ...preferenceValues } = preference;
  try {
    const request = id ? patchRequest : postRequest;
    const response = yield call(request, `sites/${siteId}/user-preference`, preferenceValues);
    yield call(handleEditSitePreferenceSuccess, siteId, response);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    if (error.response?.status === HttpStatusCode.CONFLICT) {
      try {
        const response = yield call(patchRequest, `sites/${siteId}/user-preference`, preferenceValues);
        yield call(handleEditSitePreferenceSuccess, siteId, response);
      } catch (patchError) {
        if (!axios.isAxiosError(patchError)) throw patchError;
        yield call(handleEditSitePreferenceFailure, patchError);
      }
    } else {
      yield call(handleEditSitePreferenceFailure, error);
    }
  }
}

export function* watchEditSitePreferenceRequest() {
  yield takeLatest(ActionTypes.EDIT_SITE_PREFERENCE_REQUEST, requestEditSitePreference);
}

export function* requestDeleteSite(action: ReturnType<typeof deleteSite>) {
  const { payload: { id: siteId, onSuccess } } = action;

  try {
    const response: AxiosResponse = yield call(deleteRequest, `site/${siteId}`);
    if (onSuccess) onSuccess();
    yield put(deleteSiteSuccess(response));
    yield put(
      trackEvent({
        type: EventType.SITE_REMOVED,
        data: { siteId, status: true }
      })
    );
    antdMessage.success('Site removed');
    yield put(unsetSite(siteId));
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    antdMessage.error('Failed to remove site');
    yield put(deleteSiteFailure(message, error as any));
    yield put(
      trackEvent({
        type: EventType.SITE_REMOVED,
        data: { siteId, status: false }
      })
    );
  }
}

export function* watchDeleteSiteRequest() {
  yield takeLatest(ActionTypes.DELETE_SITE_REQUEST, requestDeleteSite);
}

export default function* sitesSaga() {
  yield all([
    fork(watchLoadSitesRequest),
    fork(watchAddSiteRequest),
    fork(watchEditSiteRequest),
    fork(watchEditSitePreferenceRequest),
    fork(watchDeleteSiteRequest)
  ]);
}
