import { action } from 'typesafe-actions';

import ActionTypes from './constants';

/** @deprecated Please use useSearchParams from 'react-router-dom-v5-compat' instead */
export const appendValuesToQueryString = (object: { [key: string]: unknown }) =>
  action(ActionTypes.APPEND_VALUES_TO_QUERY_STRING, object);

/** @deprecated We're not going to store search params after react router v6 migration */
export const storeSearch = (pathname: string, search: string) =>
  action(ActionTypes.STORE_SEARCH, { pathname, search });
