import round from 'lodash/round';

import { HA_TO_AC, IN_TO_CM, LengthConversion } from 'constants/unit-conversion';
import { UnitLevelMd, UnitLevelSm, UnitMatter, UnitSize } from 'utils/get-unit-by-country';

// TODO: move to constants/unit-conversion
const KG_HA_TO_LB_AC = 0.89;

export interface Unit {
  symbol:
  | '%'
  | UnitSize
  | UnitLevelMd
  | UnitLevelSm
  | UnitMatter
  prefix?: 'k' | 'm';
}

export type Reading = { metric: Quantity, locale: Quantity };

export class Quantity {
  value: number;
  unit: Unit;

  constructor(value: number, unit: Unit) {
    if (typeof value !== 'number' || isNaN(value)) {
      this.value = 0;
    } else {
      this.value = value;
    }
    this.unit = unit;
  }

  toString() {
    if (this.unit.symbol === '%') {
      return `${this.value}${this.unit.symbol}`;
    }
    if (this.unit.prefix) {
      return `${this.value} ${this.unit.prefix}${this.unit.symbol}`;
    }
    return `${this.value} ${this.unit.symbol}`;
  }
}

/** Convert Quantity to a number in another unit. Default precision is 2 decimal places. */
export default function convertUnit(
  from: Quantity,
  to: Unit,
  precision
): number {
  const {
    unit: { symbol: fromUnit, prefix: fromPrefix },
    value: fromValue
  } = from;
  const { symbol: toUnit } = to;
  // TODO: Static type checking for incompatible conversions.

  switch (fromUnit) {
    case 'cm':
      if (toUnit === 'm') {
        return round(fromValue / 100, precision);
      }
      if (toUnit === 'ft') {
        return round(fromValue / LengthConversion.FT_TO_CM, precision);
      }
      if (toUnit === 'in') {
        return round(fromValue / IN_TO_CM, precision);
      }
      break;
    case 'ha':
      if (toUnit === 'ac') {
        return round(fromValue * HA_TO_AC, precision);
      }
      break;
    case 'in':
      if (toUnit === 'cm') {
        return round(fromValue * IN_TO_CM, precision);
      }
      break;
    case 'ft':
      if (toUnit === 'cm') {
        return round(fromValue * LengthConversion.FT_TO_CM, precision);
      }
      if (toUnit === 'm') {
        return round(fromValue * LengthConversion.FT_TO_CM / 100, precision);
      }
      if (toUnit === 'in') {
        return round(fromValue * LengthConversion.FT_TO_IN, precision);
      }
      break;
    case 'kg/ha':
      if (toUnit === 'lb/ac') {
        return round(fromValue * KG_HA_TO_LB_AC, precision);
      }
      break;
    case 'm':
      if (toUnit === 'cm') {
        return round(fromValue * 100, precision);
      }
      if (toUnit === 'in' && fromPrefix === 'm') {
        return round(fromValue / 10 / IN_TO_CM, precision);
      }
      if (toUnit === 'ft') {
        return round(fromValue * 100 / LengthConversion.FT_TO_CM, precision);
      }
      break;
    default:
      return round(fromValue, precision);
  }
  if (fromUnit !== toUnit) {
    throw new Error(`Conversion from ${fromUnit} to ${toUnit} is not supported.`);
  }
  return round(fromValue, precision);
}
