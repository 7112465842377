import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import React from 'react';

import './styles.less';

type Props = Pick<
  MenuProps,
  'selectable' | 'onClick' | 'children' | 'style'
> & {
  selectedKey: string;
};

/**
 * A wrapper around the antd Menu with our custom config applied.
 * For use in main navigation side menus.
 */
function MainNavigationMenu({
  selectable,
  selectedKey,
  onClick,
  children
}: Props): JSX.Element {
  return (
    <Menu
      className="MainNavigationMenu"
      selectable={selectable}
      mode="inline"
      selectedKeys={[selectedKey]}
      onClick={onClick}
      inlineIndent={27}
    >
      {children}
    </Menu>
  );
}

export default MainNavigationMenu;
