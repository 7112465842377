import { Input, Tooltip } from 'antd';
import type { InputRef } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom-v5-compat';
import { useEffectOnce, useUpdateEffect } from 'react-use';

import { SEARCH_PARAMS } from 'constants/search-params';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import { useStoredSearch } from 'store/modules/routerUtils/hooks';

interface Props {
  className?: string;
  placeholder?: string | undefined;
  autoFocusOnLoad?: boolean;
  /**
   * Indicates whether the component is hidden by an OverlayPage (e.g., when the List is overlaid by a Details page).
   * When this is true, the Search Bar should not update the URL query parameters.
   */
  hiddenByOverlayPage?: boolean;
  size?: SizeType;
  tooltipContent?: string | JSX.Element;
  // TODO: remove this prop after https://farmbot.atlassian.net/browse/FMBT-6453
  /**
   * Components on old React Router are not using 'useApplyCachedSearchParams' yet,
   * and still rely on the dispatch(appendValuesToQueryString) method.
   */
  isOnOldRouter?: boolean;
}

/** A search bar that shares its inner value via URL query params */
function SearchInput({
  className,
  placeholder,
  autoFocusOnLoad,
  hiddenByOverlayPage,
  size = 'small',
  tooltipContent,
  isOnOldRouter
}: Props): JSX.Element {
  const { pathname } = useLocation();
  const storedSearchParams = new URLSearchParams(useStoredSearch(pathname));
  const storedSearchValue: string | undefined = storedSearchParams.get(SEARCH_PARAMS.SEARCH) || undefined;

  const [searchParams, setSearchParams] = useSearchParams();
  const searchValueFromUrl: string | undefined = searchParams.get(SEARCH_PARAMS.SEARCH) || undefined;

  const inputRef = useRef<InputRef>(null);

  const dispatch = useDispatch();

  const [val, setVal] = useState(storedSearchValue || searchValueFromUrl);
  const [tooltipTitle, setTooltipTitle] = useState(tooltipContent);

  useEffectOnce(() => {
    if (autoFocusOnLoad) {
      inputRef.current?.focus({
        cursor: 'start'
      });
    }
  });

  const updateSearchTermDebounced = useMemo(
    () =>
      debounce((searchTerm = '') => {
        const existingSearchTerm = searchParams.get(SEARCH_PARAMS.SEARCH) || '';
        if (hiddenByOverlayPage || searchTerm === existingSearchTerm) return;
        if (isOnOldRouter) {
          dispatch(
            appendValuesToQueryString({
              search: searchTerm || undefined,
              currentPage: undefined
            })
          );
          return;
        }
        setSearchParams(prev => {
          const newSearchParams = new URLSearchParams(prev);
          newSearchParams.delete(SEARCH_PARAMS.TABLE.CURRENT_PAGE); // Reset page when searching

          if (searchTerm) newSearchParams.set(SEARCH_PARAMS.SEARCH, searchTerm);
          else newSearchParams.delete(SEARCH_PARAMS.SEARCH);

          return newSearchParams;
        });
      }, 300),
    [dispatch, hiddenByOverlayPage, isOnOldRouter, searchParams, setSearchParams]
  );

  useUpdateEffect(() => {
    updateSearchTermDebounced(val);
    if (val) {
      setTooltipTitle(undefined);
    }
  }, [val, updateSearchTermDebounced]);

  return (
    <Tooltip title={tooltipTitle}>
      <Input
        className={className}
        allowClear
        size={size}
        value={val}
        placeholder={placeholder}
        onChange={({ currentTarget }) => {
          setVal(currentTarget.value);
        }}
        ref={inputRef}
        prefix={<FiSearch />}
      />
    </Tooltip>
  );
}

export default SearchInput;
