export const DEFAULT_COUNTRY_ABBR_FARMBOT = 'AUS';
export const DEFAULT_COUNTRY_ABBR_RANCHBOT = 'USA';
export const RANCHBOT_COUNTRIES_ABBR = ['USA', 'CAN', 'MEX', 'PRY'];

export const INVITATION_VALIDITY_DAYS = 30;

export const HELP_CENTRE_URL_FARMBOT =
  'https://intercom.help/farmbot-helpcentre/en/';
export const HELP_CENTRE_URL_RANCHBOT = 'https://intercom.help/ranchbot/en/';

export const FARMBOT_SALES_CONTACT_NUMBER = '02 9030 4760';
export const RANCHBOT_SALES_CONTACT_NUMBER = '512 706 9084';

export const EM_DASH = '—';

export const MAP_SUGGESTED_ZOOM_LEVEL = 17;