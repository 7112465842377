import { AxiosError } from 'axios';
import { action } from 'typesafe-actions';

import Invitation from 'types/models/invitation';
import User, { UserId } from 'types/models/user';

import ActionTypes from './constants';

export const setUsers = (users: Record<UserId, User>) =>
  action(ActionTypes.SET_USERS, users);

export const setUser = (user: User) => action(ActionTypes.SET_USER, user);

export const unsetUser = (userId: UserId) =>
  action(ActionTypes.UNSET_USER, userId);

export const addUser = (
  values: Partial<User> & { invitation: Invitation },
  onSuccess?: () => void
) => action(ActionTypes.ADD_USER_REQUEST, { values, onSuccess });

export const addUserSuccess = (user: User) =>
  action(ActionTypes.ADD_USER_SUCCESS, user);

export const addUserFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.ADD_USER_FAILURE, { message, error }, undefined, true);

export const editUser = (values: Record<string, unknown>, userId: UserId) =>
  action(ActionTypes.EDIT_USER_REQUEST, { values, userId });

export const editUserSuccess = (user: User) =>
  action(ActionTypes.EDIT_USER_SUCCESS, user);

export const editUserFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.EDIT_USER_FAILURE, { message, error }, undefined, true);

export const deleteUser = (userId: UserId, onSuccess?: () => void) =>
  action(ActionTypes.DELETE_USER_REQUEST, { userId, onSuccess });

export const deleteUserSuccess = (userId: UserId) =>
  action(ActionTypes.DELETE_USER_SUCCESS, userId);

export const deleteUserFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.DELETE_USER_FAILURE, { message, error }, undefined, true);

export const userLeaveEnterprise = (membershipId: number) =>
  action(ActionTypes.USER_LEAVE_ENTERPRISE_REQUEST, { membershipId });

export const userLeaveEnterpriseSuccess = (membershipId: number) =>
  action(ActionTypes.USER_LEAVE_ENTERPRISE_SUCCESS, { membershipId });

export const userLeaveEnterpriseFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.USER_LEAVE_ENTERPRISE_FAILURE,
    { message, error },
    undefined,
    true
  );
